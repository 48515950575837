import useLang from '@/hooks/useLang';
import {
  isLoginState,
  isOpenLRModalState,
  LRModalInitalPage,
} from '@/stores/auth';
import { homeTab as homeTabState, isShowHomeGuide } from '@/stores/config';
import { HomeTab } from '@/types';
import { AppBar, Box, Stack, Toolbar, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
// import {
//   default as IMAGE_CASINO,
//   default as IMAGE_CASINO_ACTIVE,
// } from '../../assets/nav/icon_casinoOn.svg';
// import IMAGE_INVITE from '../../assets/nav/icon_inviteOff.svg';
// import IMAGE_INVITE_ACTIVE from '../../assets/nav/icon_inviteOn.svg';
// import IMAGE_ME from '../../assets/nav/icon_meOff.svg';
// import IMAGE_ME_ACTIVE from '../../assets/nav/icon_meOn.svg';
// import IMAGE_LUCKY from '../../assets/nav/icon_spinnerOff.svg';
// import IMAGE_LUCKY_ACTIVE from '../../assets/nav/icon_spinnerOn.svg';
// import IMAGE_VIP from '../../assets/nav/icon_vipOff.svg';
// import IMAGE_VIP_ACTIVE from '../../assets/nav/icon_vipOn.svg';
// 副站-1
// import {
//   default as IMAGE_CASINO,
//   default as IMAGE_CASINO_ACTIVE,
// } from '../../assets/nav/icon_Casino_1.svg';
// import IMAGE_INVITE from '../../assets/nav/icon_Invite_off_2.svg';
// import IMAGE_INVITE_ACTIVE from '../../assets/nav/icon_Invite_on_1.svg';
// import IMAGE_ME from '../../assets/nav/icon_Person_off_2.svg';
// import IMAGE_ME_ACTIVE from '../../assets/nav/icon_Person_on_1.svg';
// import IMAGE_LUCKY from '../../assets/nav/icon_Spinner_off_2.svg';
// import IMAGE_LUCKY_ACTIVE from '../../assets/nav/icon_Spinner_on_1.svg';
// import IMAGE_VIP from '../../assets/nav/icon_VIP_off_2.svg';
// import IMAGE_VIP_ACTIVE from '../../assets/nav/icon_VIP_on_1.svg';
// 副站-2
// import {
//   default as IMAGE_CASINO,
//   default as IMAGE_CASINO_ACTIVE,
// } from '../../assets/nav/icon_Casino_2.svg';
// import IMAGE_INVITE from '../../assets/nav/icon_Invite_off_2.svg';
// import IMAGE_INVITE_ACTIVE from '../../assets/nav/icon_Invite_on_2.svg';
// import IMAGE_ME from '../../assets/nav/icon_Person_off_2.svg';
// import IMAGE_ME_ACTIVE from '../../assets/nav/icon_Person_on_2.svg';
// import IMAGE_LUCKY from '../../assets/nav/icon_Spinner_off_2.svg';
// import IMAGE_LUCKY_ACTIVE from '../../assets/nav/icon_Spinner_on_2.svg';
// import IMAGE_VIP from '../../assets/nav/icon_VIP_off_2.svg';
// import IMAGE_VIP_ACTIVE from '../../assets/nav/icon_VIP_on_2.svg';
// 副站-4
import {
  default as IMAGE_CASINO,
  default as IMAGE_CASINO_ACTIVE,
} from '../../assets/nav/icon_Casino_3.svg';
import IMAGE_INVITE from '../../assets/nav/icon_Invite_off_2.svg';
import IMAGE_INVITE_ACTIVE from '../../assets/nav/icon_Invite_on_3.svg';
import IMAGE_ME from '../../assets/nav/icon_Person_off_2.svg';
import IMAGE_ME_ACTIVE from '../../assets/nav/icon_Person_on_3.svg';
import IMAGE_LUCKY from '../../assets/nav/icon_Spinner_off_2.svg';
import IMAGE_LUCKY_ACTIVE from '../../assets/nav/icon_Spinner_on_3.svg';
import IMAGE_VIP from '../../assets/nav/icon_VIP_off_2.svg';
import IMAGE_VIP_ACTIVE from '../../assets/nav/icon_VIP_on_3.svg';
interface Props {}

const MoblieAppbar: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isLogin = useRecoilValue(isLoginState);
  const setOpenLRModal = useSetRecoilState(isOpenLRModalState);
  const setInitalPage = useSetRecoilState(LRModalInitalPage);
  const setIsShowDLGuide = useSetRecoilState(isShowHomeGuide);
  const [homeTab, setHomeTab] = useRecoilState(homeTabState);
  const { __ } = useLang();
  const handleClickWithLAuthCheck = (path: string) => {
    if (!isLogin) {
      // navigate('/signin');
      setInitalPage(0);
      setOpenLRModal(true);
    } else {
      // navigate(path);
      if (path === '/lucky') {
        setIsShowDLGuide(true);
        setHomeTab(HomeTab.Spinner);
      } else if (path === '/commissions') {
        setIsShowDLGuide(true);
        setHomeTab(HomeTab.Invite);
      } else if (path === '/vip') {
        setIsShowDLGuide(true);
        setHomeTab(HomeTab.Vip);
      } else if (path === '/me') {
        setIsShowDLGuide(true);
        setHomeTab(HomeTab.Me);
      } else {
        setHomeTab(HomeTab.Home);
      }

      if (location.pathname !== '/') {
        navigate('/');
      }
    }
  };

  return (
    <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
      <Toolbar>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          height="60px"
        >
          <Box flex={1}>
            <Stack
              justifyContent="center"
              alignItems="center"
              spacing="4px"
              onClick={() => handleClickWithLAuthCheck('/vip')}
            >
              <img
                src={
                  // location.pathname === '/vip'
                  homeTab === HomeTab.Vip ? IMAGE_VIP_ACTIVE : IMAGE_VIP
                }
                alt=""
                style={{ width: 24, height: 24 }}
              />
              <Typography variant="body2" lineHeight={1}>
                VIP
              </Typography>
            </Stack>
          </Box>
          <Box flex={1}>
            <Stack
              justifyContent="center"
              alignItems="center"
              spacing="4px"
              onClick={() => handleClickWithLAuthCheck('/lucky')}
            >
              <img
                src={
                  // location.pathname === '/lucky'
                  homeTab === HomeTab.Spinner ? IMAGE_LUCKY_ACTIVE : IMAGE_LUCKY
                }
                alt=""
                style={{ width: 24, height: 24 }}
              />
              <Typography variant="body2" lineHeight={1}>
                {__('Spinner')}
              </Typography>
            </Stack>
          </Box>

          <Box flex={1}>
            <Stack
              justifyContent="center"
              alignItems="center"
              spacing="4px"
              onClick={() => {
                setHomeTab(HomeTab.Home);
              }}
              sx={{ mb: '6px' }}
            >
              <img
                src={
                  // location.pathname === '/'
                  homeTab === HomeTab.Home ? IMAGE_CASINO_ACTIVE : IMAGE_CASINO
                }
                alt=""
                style={{ width: 68, height: 68 }}
              />
              {/*<Typography variant="body2" lineHeight={1}>*/}
              {/*  Casino*/}
              {/*</Typography>*/}
            </Stack>
          </Box>

          <Box flex={1}>
            <Stack
              justifyContent="center"
              alignItems="center"
              spacing="4px"
              onClick={() => handleClickWithLAuthCheck('/commissions')}
            >
              <img
                src={
                  // location.pathname === '/commissions'
                  homeTab === HomeTab.Invite
                    ? IMAGE_INVITE_ACTIVE
                    : IMAGE_INVITE
                }
                alt=""
                style={{ width: 24, height: 24 }}
              />
              <Typography variant="body2" lineHeight={1}>
                {__('Invite')}
              </Typography>
            </Stack>
          </Box>

          <Box flex={1}>
            <Stack
              justifyContent="center"
              alignItems="center"
              spacing="4px"
              onClick={() => handleClickWithLAuthCheck('/me')}
            >
              <img
                src={
                  // location.pathname === '/me'
                  homeTab === HomeTab.Me ? IMAGE_ME_ACTIVE : IMAGE_ME
                }
                alt=""
                style={{ width: 24, height: 24 }}
              />
              <Typography variant="body2" lineHeight={1}>
                {__('ME')}
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default MoblieAppbar;
