export const styleBalanceContainer = {
  bgcolor: '#231E3A',
  borderRadius: '20px',
  flex: 1,
  position: 'relative',
  height: '196px',
  overflow: 'hidden',
};

export const hoverMenuBg = 'linear-gradient(90deg, #F0CDFF 0%, #CFE7FE 100%)';

export const ColorfulBg = '#0072FF';//副-1：E53935；副-2：E5C62ED；副-1：E53935；副-1：E53935；
